import { lazy } from "react";
const HomePage = lazy(() => import("@pages/HomePage"));
const LoginPage = lazy(() => import("@pages/Login"));
const AboutPage = lazy(() => import("@pages/About"));
const ItemDetailsPage = lazy(() => import("@pages/ItemDetailsPage"));
const AuthorDetailPage = lazy(() => import("@pages/AuthorDetailPage"));
const SideNavTestLayout = lazy(() => import("@components/SideNav"));
const FilteredResources = lazy(() => import("@components/FilteredResources"));
import { TagsContextProvider } from "@state/context/TagsContext";

type RouteObject = {
  path: string;
  element: React.ReactNode;
  restricted?: boolean;
  children?: RouteObject[];
};

const PublicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <LoginPage />,
    restricted: true, // restriced flag in public routes indicate that only non-authenticated user can access this route
  },
  {
    path: "/login",
    element: <LoginPage />,
    restricted: true,
  },
  {
    path: "/policy",
    element: <h1>Policy</h1>,
    restricted: false,
  },
];

const PrivateRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <TagsContextProvider>
        <SideNavTestLayout />
      </TagsContextProvider>
    ),
    children: [
      {
        path: "home",
        element: <HomePage />,
      },
      {
        path: "browse",
        element: <FilteredResources />,
      },
    ],
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/item/:id",
    element: <ItemDetailsPage />,
  },
  { path: "/author/:id", element: <AuthorDetailPage /> },
];

export { PublicRoutes, PrivateRoutes };
