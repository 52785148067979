import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "state/context/AuthContext";
interface PublicRouteProps {
  restricted: boolean | undefined;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ restricted, ...rest }) => {
  const {
    state: { isAuthenticated },
  } = useAuthContext();

  if (isAuthenticated && restricted) {
    /* if user is authenticated and trying to access the restriced routes, (/login, /logout)
     i.e can be accessed by non-logged in users only then return them to home page */
    return <Navigate to="/home" replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
