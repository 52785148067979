import { Box, Grid, Image, Link, Text } from "@pingux/astro";
import {
  FooterNavItemProps,
  LinkProps,
  copyrightText,
  footerBottomLinks,
  footerLogo,
  footerNavLinks,
} from "./FooterConfig";
import { footerSX } from "./FooterSX";

const Footer = () => {
  return (
    <Box as="footer" sx={footerSX.footerContainer}>
      <Box sx={{ ...footerSX.footerWrapper }}>
        {footerLogo && (
          <Box py="lg">
            <Box py="sm" px="md">
              <Image
                src={footerLogo}
                sx={footerSX.logo}
                alt="logo"
                aria-label="logo"
              />
            </Box>
          </Box>
        )}

        {footerNavLinks && (
          <Grid columns={[2, 2, 4, 5, 5, 5]} gap={["30px"]}>
            {footerNavLinks.map((col: FooterNavItemProps[], key) => (
              <Box key={`${col[0]?.header} + ${key}`}>
                {col.map((section: FooterNavItemProps) => (
                  <Box as="ul" mb="lg" key={section?.header}>
                    {section.header && (
                      <Box as="li">
                        <Text sx={footerSX.footerHeader}>{section.header}</Text>
                      </Box>
                    )}
                    {section.links.map((link) => (
                      <Box as="li" key={link?.linkProps?.["aria-label"]}>
                        <Link {...link.linkProps} sx={footerSX.navLink} />
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </Grid>
        )}

        {copyrightText && (
          <Box mb="sm" textAlign="center">
            <Text sx={footerSX.footerText}>{copyrightText}</Text>
          </Box>
        )}

        {footerBottomLinks && (
          <Grid
            columns={[
              "repeat(2, max-content)",
              "repeat(2, max-content)",
              "repeat(auto-fit, minmax(0, 1fr))",
              "repeat(auto-fit, minmax(max-content, 1px))",
            ]}
            sx={footerSX.footerBottomLinkContainer}
            as="ul"
            gap="0"
          >
            {footerBottomLinks.map((link, index) => (
              <Box as="li" key={`${link?.href} + ${index}`}>
                <Link {...link} sx={footerSX.footerBottomLinks} />
              </Box>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
