import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Image,
  NavigationHeader,
  Link,
  Separator,
  Text,
  IconButtonToggle,
  OverlayProvider,
} from "@pingux/astro";
import { menuItems, HEADER_LOGO, DISPLAY_NAME } from "./HeaderConfig";
import HelpButton from "./HelpButton";
import WhiteBalanceSunnyIcon from "@pingux/mdi-react/WhiteBalanceSunnyIcon";
import MoonWaningCrescentIcon from "@pingux/mdi-react/MoonWaningCrescentIcon";
import { BASE_URL, MY_CONTENT } from "shared/constants/routes";
import { headerSX } from "./HeaderSX";
import { PING_IDENTITY_URL } from "utils/constants";

const Header = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const toggleSignIn = () => {
    setIsLoggedIn(!isLoggedIn);
  };

  const goToMyContent = () => {
    navigate(`${BASE_URL}/'${MY_CONTENT}'`);
  };

  return (
    <NavigationHeader
      sx={headerSX.headerContainer}
      aria-labelledby="next-gen-header"
    >
      <Box
        isRow
        alignItems="center"
        justifyContent="center"
        py="sm"
        flex="0 0 auto"
      >
        <Link target="_blank" href={PING_IDENTITY_URL}>
          <Image
            src={HEADER_LOGO}
            alt="Ping Identity Logo"
            mr="md"
            sx={{
              height: "24px",
              cursor: "pointer",
            }}
          />
        </Link>

        <Separator
          orientation="vertical"
          style={{
            height: "28px",
            margin: "0",
          }}
        />
        <Link
          variant="navigationHeader.logoBand"
          onPress={() => navigate(`${BASE_URL}/home`)}
        >
          <Text
            as="h2"
            variant="navigationHeader.headerPlaceholder"
            id="next-gen-header"
            sx={{
              cursor: "pointer",
              color: "rgba(0,0,0,0.9)",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {DISPLAY_NAME}
          </Text>
        </Link>
      </Box>
      <OverlayProvider>
        <Box as="ul" isRow alignItems="center" p="0" ml="auto" flex="0 0 auto">
          <HelpButton items={menuItems} />
          <Box as="li">
            {/* DarkMode is not available yet, therefore the following code is commented out. */}
            {/* <IconButtonToggle
              toggledIcon={WhiteBalanceSunnyIcon}
              defaultIcon={MoonWaningCrescentIcon}
              iconProps={{
                size: "24px",
              }}
              buttonProps={{
                "aria-label": "theme icon",
                variant: "headerNav",
              }}
            /> */}
          </Box>
        </Box>
      </OverlayProvider>
    </NavigationHeader>
  );
};

export default Header;
