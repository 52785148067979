import apiClient from "./api/request";
import { API_ENDPOINTS } from "./apiEndpoints";

export const fetchTagsData = async () => {
  const response = await apiClient.get(API_ENDPOINTS.GET_TAGS);
  return response.data;
};

export const fetchSchema = async (configKey: string) => {
  const response = await apiClient.get(
    API_ENDPOINTS.GET_TAGS_SCHEMA(configKey)
  );
  return response.data;
};
