import QuestionMarkCircleOutlineIcon from "@pingux/mdi-react/QuestionMarkCircleOutlineIcon";
import { Box, IconButton, PopoverMenu, Item, Menu, Icon } from "@pingux/astro";
import { headerSX } from "./HeaderSX";

interface DropdownProps {
  items: { label: string; url: string }[];
}

const HelpButton: React.FC<DropdownProps> = ({ items }) => {
  return (
    <Box>
      <PopoverMenu>
        <IconButton aria-label="Help">
          <Icon
            icon={QuestionMarkCircleOutlineIcon}
            size="md"
            title={{
              name: "Help Icon",
            }}
            variant=""
          />
        </IconButton>
        <Menu>
          {items.map((item) => (
            <Item
              as="a"
              variant="a"
              key={item?.url}
              target="_blank"
              href={item?.url}
              sx={headerSX.supportLinks}
              aria-label={item?.label}
            >
              {item?.label}
            </Item>
          ))}
        </Menu>
      </PopoverMenu>
    </Box>
  );
};

export default HelpButton;
