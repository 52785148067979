import {
  COLORS,
  SPACING,
  FONT,
  OPACITY,
  DIMENSIONS,
} from "@assets/styles/styleConstants";

const footerContainer = {
  bg: COLORS.gray100,
  width: "100%",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
};

const footerWrapper = {
  p: ["lg", "lg", "lg", "lg", "2.5rem", "2.5rem", "2.5rem"],
  maxWidth: "1540px",
  width: "100%",
  flexGrow: 1,
};

const logo = {
  opacity: OPACITY.semiTransparent,
  height: DIMENSIONS.logoHeight,
};

const navLink = {
  fontSize: FONT.small,
  color: COLORS.gray800,
  py: SPACING.small,
  px: SPACING.medium,
  lineHeight: "body",
  textDecoration: "none",
  "&.is-hovered": {
    textDecoration: "none",
    color: COLORS.blueHover,
  },
  "&.is-focused": {
    textDecoration: "none",
    color: COLORS.blueHover,
  },
  "&.is-pressed": {
    textDecoration: "none",
    color: COLORS.blueHover,
  },
  "&.is-visited": {
    textDecoration: "none",
    color: COLORS.blueHover,
  },
};

const footerHeader = {
  ...navLink,
  fontWeight: FONT.mediumWeight,
};

const footerText = {
  lineHeight: "body",
  fontSize: FONT.small,
};

const footerBottomLinkContainer = {
  mb: SPACING.large,
  justifyContent: "center",
  alignItems: "center",
  "& li:last-child > a:after": {
    display: "none",
  },
};

const footerBottomLinks = {
  ...navLink,
  "&:after": {
    content: '"•"',
    ml: SPACING.large,
    opacity: OPACITY.semiTransparent,
  },
};

export const footerSX = {
  footerContainer,
  footerWrapper,
  logo,
  navLink,
  footerHeader,
  footerText,
  footerBottomLinkContainer,
  footerBottomLinks,
};
