import {
  useContext,
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import useFetchTags from "hooks/useFetchTagHook";
import { INITIAL_FILTER_STATE } from "@pages/HomePage/constants";
import { SchemaConfig, TagEntity, Tags } from "types/tagHooksTypes";
import { BROWSE, HOME } from "shared/constants/routes";
import { debounce, isEmpty } from "utils/commonUtils";

type FilterState = {
  query: Record<string, any>;
  selectedKeyMapping: Record<string, any>;
  tags: Record<string, any>;
};

type TagState = {
  filter: FilterState;
  tags: Tags | null;
  tagsLoading: boolean;
  tagsError: any;
  tagsSchemaConfig: SchemaConfig[] | null;
  setFilter: React.Dispatch<React.SetStateAction<FilterState>>;
  handleFilter: (selectedFilterInfo: {
    tagType: string;
    tagInfo: TagEntity;
  }) => void;
  handleRemoveFilter: (key: string) => void;
  handleClearAll: () => void;
  isURLProcessing: boolean | null;
  searchRef: React.MutableRefObject<string>;
  // handleItemSearchWithDebounce?: (searchedString: string) => void;
  handleSearch: (searchedString: string) => void;
  searchValue: string;
};

const TagsContext = createContext<TagState | undefined>(undefined);

const TagsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [filter, setFilter] = useState<FilterState>(INITIAL_FILTER_STATE);
  const { tags, tagsError, tagsLoading, tagsSchemaConfig } = useFetchTags();
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isURLProcessing, setURLProcessing] = useState<boolean | null>(
    Array.from(urlSearchParams)?.length != 0 && true
  );
  const [isSideNavFilterUsed, setIsSideNavFilterUsed] =
    useState<boolean>(false);
  const searchRef = useRef<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    // Not as of much use now as for home page we always need to call the fetch Items Grouped by collection
    if (location.pathname.includes(HOME) && !isEmpty(filter?.query)) {
      setFilter(INITIAL_FILTER_STATE);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isURLProcessing && tagsError) {
      setURLProcessing(null);
    }
  }, [tagsError]);

  useEffect(() => {
    if (
      !location.pathname.includes(BROWSE) ||
      Array.from(urlSearchParams)?.length === 0 ||
      !tags ||
      !tagsSchemaConfig ||
      isSideNavFilterUsed
    ) {
      return;
    }
    const updatedFilter = {
      query: {
        ...filter?.query,
      },
      tags: {
        ...filter?.tags,
      },
      selectedKeyMapping: {
        ...filter?.selectedKeyMapping,
      },
    };

    let trimmedType = "";
    let trimmedFilterId = "";

    urlSearchParams.forEach((filterId, type) => {
      trimmedType = type.trim();
      trimmedFilterId = filterId.trim(); // codeSnippets, featured, authorization

      if (
        (updatedFilter?.query?.["categories"] &&
          trimmedType === "collections") ||
        (updatedFilter?.query["collections"] && trimmedType === "categories")
      )
        return;

      /*
        Here filterId is the tagid which we need to send in the API i.e collections, categories, etc.
        comes form the fetch schema API so what we need to do is that we need to map and find the schem object of the
        selected key

        Example: collections has this schema config :
        {
          id: "collections",
          name: "Collections",
          uiType: "list",
          tagType: "collection",
          order: 3,
        }
        */
      const schemaObject =
        tagsSchemaConfig &&
        tagsSchemaConfig?.find(
          (schemaConfig) => trimmedType === schemaConfig?.id
        );

      /*
          Finding the tagInfoObject from the tagsApi Response{ tagInfo: {id: '', name: '', description: ''}
          base on the tagType i.e colletion considering the above example
        */
      const tagInfo =
        schemaObject &&
        tags &&
        tags?.[schemaObject["tagType"]]?.find(
          (tagObject: TagEntity) => tagObject.id === trimmedFilterId
        );

      if (tagInfo && schemaObject) {
        updatedFilter.query[trimmedType] = trimmedFilterId;
        /*
          eg: { products: {id: '', name: '', description: ''} }
          storing this whole object so that on the browse page, we can show the description as well
        */
        updatedFilter.tags[trimmedType] = tagInfo;
        updatedFilter.selectedKeyMapping[trimmedType] = tagInfo?.id;
      }
    });

    // only set the filter state if it has any change
    if (!isEmpty(updatedFilter?.query)) {
      setURLProcessing(false); // URL is processed, & its a valid url
      setFilter(updatedFilter);
      // setIsValidURL(true)
    } else {
      setFilter(updatedFilter);
      setURLProcessing(null);
    }
  }, [urlSearchParams, tags, tagsSchemaConfig]);

  const handleFilter = useCallback(
    (selectedFilterInfo: { tagType: string; tagInfo: TagEntity }) => {
      /*
        Structure
          { tagInfo: {id: '', name: '', description: ''}
            tagType: "products" / "contentType" / categories / collection
          }
      */
      // urlSearchParams.forEach((v, k) => console.log("url search params", v, k));

      const { tagType, tagInfo } = selectedFilterInfo;
      const trimmedType = tagType.trim();
      const trimmedId = tagInfo?.id.trim();

      // return if query already has the same id selected
      if (trimmedId === filter?.query?.[trimmedType]) return;

      const updatedFilter = {
        query: {
          ...filter?.query,
        },
        tags: {
          ...filter?.tags,
        },
        selectedKeyMapping: {
          ...filter?.selectedKeyMapping,
        },
      };

      if (trimmedType === "collections") {
        if (filter?.query?.["categories"]) {
          delete updatedFilter.query["categories"];
          delete updatedFilter.tags["categories"];
          delete updatedFilter.selectedKeyMapping["categories"];
        }
        // we need to delete "categories" from the URL if any tag from "collection" is being selected
        urlSearchParams.delete("categories");
      }
      if (trimmedType === "categories") {
        if (filter?.query?.["collections"]) {
          delete updatedFilter.query["collections"];
          delete updatedFilter.tags["collections"];
          delete updatedFilter.selectedKeyMapping["collections"];
        }
        // we need to delete "collections" from the URL if any tag from "categories" is being selected
        urlSearchParams.delete("collections");
      }

      updatedFilter.query[trimmedType] = trimmedId;
      /*
        eg: { products: {id: '', name: '', description: ''} }
        storing this whole object so that on the browse page, we can show the description as well
      */
      updatedFilter.tags[trimmedType] = tagInfo;
      updatedFilter.selectedKeyMapping[trimmedType] = tagInfo?.id;
      urlSearchParams.set(trimmedType, trimmedId);

      setFilter(updatedFilter);
      setIsSideNavFilterUsed(true);
      if (location.pathname.includes(HOME)) {
        // if we're on home page then only navigate otherwise just add the URL search params
        navigate({
          pathname: BROWSE,
          search: `?${urlSearchParams.toString()}`,
        });
      } else {
        setURLSearchParams(urlSearchParams);
      }
    },
    [filter]
  );

  const handleRemoveFilter = (key: string) => {
    setFilter((prevFilter) => {
      const updatedFilter = {
        ...prevFilter,
        query: { ...prevFilter.query },
        tags: { ...prevFilter.tags },
        selectedKeyMapping: { ...prevFilter.selectedKeyMapping },
      };

      delete updatedFilter.query[key];
      delete updatedFilter.tags[key];
      delete updatedFilter.selectedKeyMapping[key];

      if (key === "searched") {
        searchRef.current = "";
        setSearchValue("");
        delete updatedFilter.query.q;
      }

      return updatedFilter;
    });

    urlSearchParams.delete(key);
    setURLSearchParams(urlSearchParams);
  };

  const handleClearAll = () => {
    searchRef.current = "";
    setSearchValue("");
    setURLSearchParams({});
    setFilter(INITIAL_FILTER_STATE);
  };

  const handleSearchWithDebounce = useCallback(
    debounce(() => {
      setFilter((prevFilter) => {
        const currentSearchString = searchRef.current;
        const updatedFilterQuery = { ...prevFilter?.query };
        const updatedTags = { ...prevFilter?.tags };

        if (currentSearchString?.length > 0) {
          updatedFilterQuery.q = currentSearchString;
          updatedTags["searched"] = { name: currentSearchString };
          if (location.pathname.includes(HOME)) {
            // if we're on home page then only navigate otherwise just add the URL search params
            navigate({
              pathname: BROWSE,
            });
          }
        } else {
          delete updatedFilterQuery.q;
          delete updatedTags["searched"];
        }

        return {
          ...prevFilter,
          tags: updatedTags,
          query: updatedFilterQuery,
        };
      });
    }),
    []
  );

  const handleSearch = useCallback(
    (searchText: string) => {
      searchRef.current = searchText;
      setSearchValue(searchText);
      handleSearchWithDebounce(); // Debounce the filter/query update
    },
    [handleSearchWithDebounce]
  );

  return (
    <TagsContext.Provider
      value={{
        tags,
        tagsLoading,
        tagsError,
        tagsSchemaConfig,
        filter,
        setFilter,
        handleFilter,
        handleRemoveFilter,
        handleClearAll,
        isURLProcessing,
        searchRef,
        handleSearch,
        searchValue,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

const useTagsContext = () => {
  const context = useContext(TagsContext);
  if (!context) {
    throw new Error("useTagsContext must be used within a TagsContextProvider");
  }
  return context;
};

export { TagsContextProvider, useTagsContext };
