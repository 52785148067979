export const DEFAULT_SIDENAV_CONFIG = [
  {
    id: "products",
    name: "All Products",
    uiType: "select",
    tagType: "product",
    order: 1,
  },
  {
    id: "contentType",
    name: "All Types",
    uiType: "select",
    tagType: "contentType",
    order: 2,
  },
  {
    id: "collections",
    name: "Colletions",
    uiType: "list",
    tagType: "collection",
    order: 3,
  },
  {
    id: "categories",
    name: "Categories",
    tagType: "category",
    uiType: "list",
    order: 4,
  },
];
