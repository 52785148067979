import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { PublicRoutes, PrivateRoutes } from "./RoutePath";
import Layout from "@components/Layout";
import PageLoader from "@components/Loader/Loader";

const NotFoundPage = lazy(() => import("@pages/NotFound"));

const RoutesComponent = () => {
  return (
    <Layout>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {PublicRoutes.map((publicRouteObj) => (
            <Route
              key={publicRouteObj.path}
              element={<PublicRoute restricted={publicRouteObj.restricted} />}
            >
              <Route
                path={publicRouteObj.path}
                element={publicRouteObj.element}
              />
            </Route>
          ))}
          {PrivateRoutes.map((privateRouteObj) => {
            return privateRouteObj?.children?.length ? (
              <Route key={privateRouteObj.path} element={<PrivateRoute />}>
                <Route element={privateRouteObj?.element}>
                  {privateRouteObj?.children?.map((child) => (
                    <Route
                      key={child.path}
                      path={child.path}
                      element={child.element}
                    />
                  ))}
                </Route>
              </Route>
            ) : (
              <Route key={privateRouteObj.path} element={<PrivateRoute />}>
                <Route
                  key={privateRouteObj.path}
                  path={privateRouteObj.path}
                  element={privateRouteObj.element}
                />
              </Route>
            );
          })}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default RoutesComponent;
