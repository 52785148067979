// Generic request function
import axiosClient from "./axiosClient";
import { AxiosRequestConfig, Method } from "axios";

class ApiClient {
  private static instance: ApiClient;

  private constructor() {
    // Private constructor to prevent instantiation
  }

  // Create a static method to get the single instance of the class
  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  // Generic request function
  private createRequest = async (
    method: Method,
    url: string,
    data: object | undefined | null = {},
    config: AxiosRequestConfig = {}
  ) => {
    try {
      const response = await axiosClient({
        method,
        url,
        data,
        ...config,
      });

      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText || "OK",
      };
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  // GET request
  public get = (url: string, config: AxiosRequestConfig = {}) =>
    this.createRequest("GET", url, null, config);

  // POST request
  public post = (url: string, data: object, config: AxiosRequestConfig = {}) =>
    this.createRequest("POST", url, data, config);

  // PUT request
  public put = (url: string, data: object, config: AxiosRequestConfig = {}) =>
    this.createRequest("PUT", url, data, config);

  // DELETE request
  public delete = (url: string, config: AxiosRequestConfig = {}) =>
    this.createRequest("DELETE", url, null, config);
}

export default ApiClient.getInstance();
