import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { ApiErrorObject } from "types/types";
import {
  ERROR_DEFAULTS,
  API_TIMEOUT,
  NETWORK_ERROR_CODES,
} from "utils/constants";

const baseURL =
  process.env.REACT_APP_DOCKER_AWS === "true"
    ? window.location.origin
    : process.env.REACT_APP_API_HOST;

// Create axios instance
const axiosClient = axios.create({
  baseURL: `${baseURL}/api`,
  timeout: API_TIMEOUT, // Timeout after 10 seconds
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
});

// Request interceptor (e.g., for adding auth tokens)
axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // const token = localStorage.getItem('token'); // TODO: commenting for now we can add token once it is set
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor (e.g., for global error handling)
axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    const {
      response = {},
      message = ERROR_DEFAULTS?.message,
      code = "",
    } = error || {};

    const {
      data: {
        id = ERROR_DEFAULTS?.id,
        error: err = ERROR_DEFAULTS?.error,
      } = {},
      status = null,
      statusText = "ERROR",
    } = response;

    const errorObject: ApiErrorObject = {
      data: {
        id,
        error: err,
        message,
      },
      status,
      statusText,
    };

    if (NETWORK_ERROR_CODES.indexOf(code) >= 0) {
      console.error("Network error: Please check your connection.");
      errorObject.data.message = "Network error: Please check your connection.";
    }

    if (status === 401) {
      // Handle unauthorized errors (e.g., redirect to login)
    }

    return Promise.reject(errorObject);
  }
);

export default axiosClient;
