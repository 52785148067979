import { QueryParamsInterface } from "types/types";
import { collectionFilterQueryMapping } from "utils/commonUtils";
import { FILTER_QUERY_PARAM } from "utils/constants";

export const API_ENDPOINTS = {
  // Items
  GET_ITEM: (query?: QueryParamsInterface) => {
    let url = "/items";

    if (query && Object.keys(query).length) {
      let searchQuery = "";
      const queryStrings: string[] = [];

      Object.entries(query).forEach(([key, value]) => {
        const encodedValue = encodeURIComponent(String(value));

        switch (key) {
          case "collections":
            queryStrings.push(
              `(${collectionFilterQueryMapping(encodedValue)} eq ${encodeURIComponent(true)})`
            );
            break;

          case "q":
            searchQuery = `${key}=${encodedValue}`;
            break;

          default:
            queryStrings.push(`(${key} eq ${encodedValue})`);
            break;
        }
      });

      if (queryStrings?.length) {
        url += `?${FILTER_QUERY_PARAM}=(${queryStrings.join(" and ")})`;
      }

      if (searchQuery) {
        url += queryStrings.length ? `&${searchQuery}` : `?${searchQuery}`;
      }
    }

    return url;
  },
  GET_ITEM_BY_ID: (id: string) => `/items/${encodeURIComponent(id)}`,
  GET_ITEM_BY_ALIAS: (alias: string) =>
    `/items/alias:${encodeURIComponent(alias)}`,
  POST_ITEM: "/items",
  PUT_ITEM: (id: string) => `/items/${id}`,
  DELETE_ITEM: (id: string) => `/items/${id}`,
  GET_GROUPED_ITEM_DATA: (groupByParam = "collections") =>
    `/items?groupBy=${groupByParam}`,

  // Author
  GET_AUTHOR: (id: string) => `/authors/${encodeURIComponent(id)}`,

  // Tags
  GET_TAGS: `/tags?referencedOnly=true`,
  GET_TAGS_SCHEMA: (configKey: string) => `/configs/${configKey}`,

  // Version
  GET_VERSION: (versionId: string) =>
    `/versions/${encodeURIComponent(versionId)}`,
};
