import Header from "@components/Header";
import Footer from "@components/Footer";
import { Box } from "@pingux/astro";
import { LayoutSX } from "./LayoutSX";
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Box as="main" sx={LayoutSX.mainContainer}>
        <Box sx={LayoutSX?.childrenWrapper}>{children}</Box>
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
